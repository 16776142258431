<script>
import { POINT_MARKER_ICON_CONFIG } from "./constants/mapSettings";

export default {
  name: "GoogleMapMarker",
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    const { Marker, InfoWindow } = this.google.maps;

    const marker = new Marker({
      position: this.marker.position,
      map: this.map,
    });

    const infoWindow = new InfoWindow({
      content: `<div class="custom-marker-content">
                  <div class="marker-text">
                    <h3>${this.marker.title}</h3>
                    <div>
                      <p><b>Bitiş</b>:İlan Başlamadı</p>
                    <p><b>Şuan ki fiyat</b>: İhale Başlamadı</p>
  
                    </div>
                  </div>
                  <div class="marker-info">
                    <span class="marker-status">İlanda</span>
                    <div class="marker-price">${this.marker.price}₺</div>
                  </div>
                </div>
          `,
    });

    marker.addListener("click", () => {
      infoWindow.open(this.map, marker);
    });
  },

  render() {},
};
</script>
